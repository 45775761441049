const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  page403: '/403',
  page404: '/404',
  page500: '/500',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/login`,
      federatedLogin: `${ROOTS.AUTH}/federated-login`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    asctdTestResults: `${ROOTS.DASHBOARD}/asctd-test-results-report`,
    asctdInventory: `${ROOTS.DASHBOARD}/asctd-inventory-report`,
    passedTestSummary: `${ROOTS.DASHBOARD}/passed-test-summary`,
    sftpConfiguration: {
      root: `${ROOTS.DASHBOARD}/sftp-configuration`,
      new: `${ROOTS.DASHBOARD}/sftp-configuration/new`,
      edit: (id) => `${ROOTS.DASHBOARD}/sftp-configuration/${id}/edit`
    },
    user: {
      account: `${ROOTS.DASHBOARD}/user/account`,
    },
  },
};
