import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

export const API_ENDPOINTS = {
  calendar: '/api/calendar', // examaple

  auth: {
    login: '/authentication',
    users: '/users',
  },
  searches: '/report-criteria',
  reports: {
    testResults: '/reports', // report 1
    inventory: '/asctd-testers', // report 2
    passedTest: '/reports/passed-test-summary', // report 3
  },
  sftpConfigurations: '/sftp-configurations',
  uploadKey: '/upload-key',
  manageConfig: '/manage-config',
};
