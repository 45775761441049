import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { useEffect, useMemo, useCallback, useState } from 'react';
// hooks
import { useLocalStorage } from 'src/hooks/use-local-storage';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { localStorageGetItem } from 'src/utils/storage-available';
//
import { SettingsContext } from './settings-context';

// ----------------------------------------------------------------------

export function SettingsProvider({ children, defaultSettings }) {
  const [openDrawer, setOpenDrawer] = useState(false);
  
  const [settings, setSettings] = useLocalStorage('settings', defaultSettings);
  const [isVersionLoaded, setIsVersionLoaded] = useState(false);

  const isArabic = localStorageGetItem('i18nextLng') === 'ar';

  // Fetch version number from API
  useEffect(() => {
    const fetchVersion = async () => {
      try {
        const response = await axios.get(API_ENDPOINTS.manageConfig, {
          params: {
            name: 'version',
          },
        });
        const version = response?.data?.total === 1 ? response?.data?.data[0]?.value : '1.0.0'; // Fallback version
        setSettings((prevSettings) => ({
          ...prevSettings,
          version,
        }));
        setIsVersionLoaded(true);
      } catch (error) {
        console.error('Failed to fetch version:', error);
        setIsVersionLoaded(true);
      }
    };

    if (!isVersionLoaded) {
      fetchVersion();
    }
  }, [isVersionLoaded, setSettings]);

  useEffect(() => {
    if (isArabic) {
      onChangeDirectionByLang('ar');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArabic]);

  const onUpdate = useCallback(
    (name, value) => {
      setSettings((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [setSettings]
  );

  // Direction by lang
  const onChangeDirectionByLang = useCallback(
    (lang) => {
      onUpdate('themeDirection', lang === 'ar' ? 'rtl' : 'ltr');
    },
    [onUpdate]
  );

  // Reset
  const onReset = useCallback(() => {
    setSettings(defaultSettings);
  }, [defaultSettings, setSettings]);

  // Drawer
  const onToggleDrawer = useCallback(() => {
    setOpenDrawer((prev) => !prev);
  }, []);

  const onCloseDrawer = useCallback(() => {
    setOpenDrawer(false);
  }, []);

  const canReset = !isEqual(settings, defaultSettings);

  const memoizedValue = useMemo(
    () => ({
      ...settings,
      onUpdate,
      // Direction
      onChangeDirectionByLang,
      // Reset
      canReset,
      onReset,
      // Drawer
      open: openDrawer,
      onToggle: onToggleDrawer,
      onClose: onCloseDrawer,
    }),
    [
      onReset,
      onUpdate,
      settings,
      canReset,
      openDrawer,
      onCloseDrawer,
      onToggleDrawer,
      onChangeDirectionByLang,
    ]
  );

  return <SettingsContext.Provider value={memoizedValue}>{children}</SettingsContext.Provider>;
}

SettingsProvider.propTypes = {
  children: PropTypes.node,
  defaultSettings: PropTypes.object,
};
