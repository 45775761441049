import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';
//
import { msalConfig, AZURE_GRAPH_API } from 'src/config-global';
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);

      // console.log('initialize user state = ', state.user);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const jsonUser = sessionStorage.getItem('user');

        const user = JSON.parse(jsonUser);

        dispatch({
          type: 'INITIAL',
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (username, password) => {
    const data = {
      strategy: 'local',
      username,
      password,
    };

    const jsonData = JSON.stringify(data);

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await axios.post(API_ENDPOINTS.auth.login, jsonData, config);

    const { accessToken, user } = response.data;

    sessionStorage.setItem(STORAGE_KEY, accessToken);

    const jsonUserData = JSON.stringify(user);

    sessionStorage.setItem('user', jsonUserData);

    setSession(accessToken);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  }, []);

  const federatedLogin = useCallback(async (email) => {
    const response = await axios.get(`${API_ENDPOINTS.auth.users}?email=${email}`);

    let user;
    if (response?.data) user = response.data[0];

    if (user) {
      if (user.jportal_access === 1 && user.status === '1' && user.inactive === null) {
        const msalInstance = new PublicClientApplication(msalConfig);

        const loginResponse = await msalInstance.loginPopup({
          scopes: ['User.Read'],
        });

        const payload = await axios(AZURE_GRAPH_API, {
          headers: {
            Authorization: `Bearer ${loginResponse.accessToken}`,
          },
        });

        const userData = payload.data;

        const { accessToken } = loginResponse;

        if (userData.mail === email && accessToken) {
          sessionStorage.setItem(STORAGE_KEY, accessToken);

          const jsonUserData = JSON.stringify(user);

          sessionStorage.setItem('user', jsonUserData);

          setSession(accessToken);

          dispatch({
            type: 'LOGIN',
            payload: {
              user,
            },
          });
        } else {
          throw new Error(
            `Your federated account email doesn't match with Buckeye Mountain account email`
          );
        }
      } else {
        throw new Error(`This user does not have permission to login`);
      }
    } else {
      throw new Error(`This email address doesn't match with any Buckeye Mountain account`);
    }
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      federatedLogin,
      logout,
    }),
    [login, federatedLogin, logout, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
