import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// USER
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));

// ASCTD Test Results Report Page
const ASCTDTestResultsReportPage = lazy(() =>
  import('src/pages/dashboard/asctd-test-results-report')
);

// ASCTD Inventory Report Page
const InventoryReportPage = lazy(() => import('src/pages/dashboard/inventory-report'));

// Most Recent Passed Test Summary
const PassedTestSummaryPage = lazy(() => import('src/pages/dashboard/passed-test-summary'));

// Sftp Configurations
const SftpConfigurationListPage = lazy(() => import('src/pages/dashboard/sftpConfiguration/list'));
const SftpConfigurationCreatePage = lazy(() => import('src/pages/dashboard/sftpConfiguration/new'));
const SftpConfigurationEditPage = lazy(() => import('src/pages/dashboard/sftpConfiguration/edit'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: 'user',
        children: [
          { path: 'account', element: <UserAccountPage /> },
        ],
      },
      { path: 'asctd-test-results-report', element: <ASCTDTestResultsReportPage /> },
      { path: 'asctd-inventory-report', element: <InventoryReportPage /> },
      { path: 'passed-test-summary', element: <PassedTestSummaryPage /> },
      {
        path: 'sftp-configuration',
        children: [
          { element: <SftpConfigurationListPage />, index: true },
          { path: 'new', element: <SftpConfigurationCreatePage /> },
          { path: ':id/edit', element: <SftpConfigurationEditPage /> },
        ],
      },
    ],
  },
];
