import { useLocation } from 'react-router-dom';

// ----------------------------------------------------------------------

export const useFilename = () => {
  const { pathname } = useLocation();
  
  const filenameMap = {
    '/dashboard/asctd-test-results-report': 'Report 1 - ASCTD Test Results Report',
    '/dashboard/asctd-inventory-report': 'Report 2 - ASCTD Inventory Report',
    '/dashboard/passed-test-summary': 'Report 3 - Most Recent Passed Test Summary',
    '/dashboard/sftp-configuration': 'SFTP Configuration',
  };
  
  return filenameMap[pathname] || 'Anonymous Report';
};