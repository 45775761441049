import { LogLevel } from '@azure/msal-browser';
// routes
import { paths } from 'src/routes/paths';

// MUI XGRID KEY
export const REACT_APP_XGRID_KEY = process.env.REACT_APP_XGRID_KEY;

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API;

export const AZURE_GRAPH_API = process.env.REACT_APP_AZURE_GRAPH_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.user.account; // as '/dashboard/user/account'

// ROOT PATH BEFORE LOGIN SUCCESSFUL
export const PATH_BEFORE_LOGIN = paths.auth.jwt.login; // login

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI,
    authority: process.env.REACT_APP_AZURE_AUTHORITY,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          // case LogLevel.Info:
          //   console.info(message);
          //   return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            break;
          default:
        }
      },
    },
  },
};
