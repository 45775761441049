import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: t('management'),
        items: [
          // USER
          {
            title: t('user'),
            path: paths.dashboard.user.account,
            icon: ICONS.user,
          },
          // ASCTD Report
          {
            title: t('Report 1 - ASCTD Test Results Report'),
            path: paths.dashboard.asctdTestResults,
            icon: ICONS.file,
          },

          // Inventory Report
          {
            title: t('Report 2 - ASCTD Inventory Report'),
            path: paths.dashboard.asctdInventory,
            icon: ICONS.file,
          },
          // Passed Test Summary Report
          {
            title: t('Report 3 - Most Recent Passed Test Summary'),
            path: paths.dashboard.passedTestSummary,
            icon: ICONS.file,
          },
          // Sftp Configurations
          {
            title: t('Sftp Configurations'),
            path: paths.dashboard.sftpConfiguration.root,
            icon: ICONS.lock,
            roles: ["300"],
          },
        ],
      },
    ],
    [t]
  );

  return data;
}
