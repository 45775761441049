import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/auth/guard';
// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';
// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const FederatedLoginPage = lazy(() => import('src/pages/auth/jwt/federated-login'));

const authJwt = {
  children: [
    {
      path: 'login',
      element: (
        <AuthClassicLayout title="">
          <JwtLoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'federated-login',
      element: (
        <AuthClassicLayout title="">
          <FederatedLoginPage />
        </AuthClassicLayout>
      ),
    },
  ],
};

export const authRoutes = [
  {
    path: 'auth',
    element: (
      <GuestGuard>
        <Outlet />
      </GuestGuard>
    ),
    children: [authJwt],
  },
];
